import React from 'react';

import {
    Footer,
} from 'reaxl-footer-demo';

export default function DemoFooter() {

    return (
        <Footer
            atcPartnerLogoSrc="/content/static/img/partner/ford/ford-footer-atc-logo.svg"
            partnerLogoSrc="/content/static/img/partner/partner-footer-logo.png"
        />
    );
}
